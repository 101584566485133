<template>
  <div id="virtual-benefit-fair-subview">
    <div class="mb-4 prose prose-md md:prose-lg">
      <h1>Virtual Benefit Fair</h1>
      <p>
        In response to the ever changing work environment caused by COVID-19 we
        are excited to offer to you and your participants the opportunity to
        learn more about benefits administered by Rocky Mountain Reserve through
        our Virtual Benefit Fair!
      </p>
      <p>
        Your company may choose to utilize all or just a few of the options
        available. We hope that this eases some of the burden during open
        enrollment.
      </p>
    </div>
    <!-- <div class="p-4 mb-4 border border-solid rounded-lg border-neutral">
      <div class="flex flex-row items-center mb-4">
        <h2 class="flex-1 text-2xl font-semibold text-primary">
          Option 1: Weekly webinar open to all our clients
        </h2>
      </div>
      <div class="max-w-full prose-sm prose">
        <p>
          <b>This webinar will run Sept 29th to December 15th</b>
        </p>
        <p>
          <em>
            These are not limited to one client. There may be participants from
            other companies who utilize or will utilize RMR for the upcoming
            year.
          </em>
        </p>
        <ul>
          <li>
            During these meetings, one of our knowledgeable employees will run
            through a short presentation in regard to what a Cafeteria plan
            entails and some of the changes that have occurred with the plans
            especially in regard to the CARES act.
          </li>
          <li>
            Following the presentation, there will be some time to ask any
            questions you or your employees may have.
          </li>
          <li>
            These will be held
            <a
              class="inline"
              href="https://zoom.us/webinar/register/WN_7je4qV-IS_y9FCgQ33FwNg"
              >via ZOOM</a
            >
            <ul>
              <li>Wednesday 10 am MST</li>
              <li>
                Feel free to join any of these webinars or send your employees
                to join
              </li>
              <li>There will be Q&A after each webinar</li>
            </ul>
          </li>
        </ul>
      </div>
    </div> -->

    <div
      class="p-4 mb-4 border border-solid rounded-lg bg-neutral-light border-neutral"
    >
      <div class="flex flex-row items-center mb-4">
        <h2 class="flex-1 text-2xl font-semibold text-primary">
          On Demand
        </h2>
      </div>
      <div class="max-w-full mb-4 prose-sm prose">
        <ul class="text-sm">
          <li>
            Not able to attend on one of our weekly calls? No problem, see the
            videos below to view a prerecorded presentation on your own
            schedule.
          </li>
          <li>
            Contact our customer service center
            <router-link class="inline" to="/contact">here</router-link>
            with more questions.
          </li>
        </ul>
      </div>
      <div class="flex flex-col items-center sm:flex-row sm:flex-wrap">
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/YcEZ-FW_vNk"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/3uTQSC27QIs"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/JI1pGdBzEBM"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/eikzOoZW3e8"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/1-vhvDnGJEY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/Q0uxfT53KO8"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/ijnO9YXPmdo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          class="h-32 mx-2 border border-solid rounded border-neutral w-60"
          src="https://www.youtube.com/embed/hpzAEBYkVtk"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- <div class="h-32 mx-2 border border-solid rounded border-neutral w-60">
          <vue-core-video-player
            :autoplay="false"
            :src="
              require(`@/assets/plans/general/Overall Guide to Your CDH Benefits Video.mp4`)
            "
          ></vue-core-video-player>
        </div> -->
      </div>
    </div>

    <!-- <div class="p-4 mb-4 border border-solid rounded-lg border-neutral">
      <div class="flex flex-row items-center mb-4">
        <h2 class="flex-1 text-2xl font-semibold text-primary">
          Option 2: Live webinar for just you and your employees
        </h2>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            One of our knowledgeable employees will run a webinar for just your
            company and participants. The meeting will include a presentation of
            the cafeteria plan benefits that your company offers and will allow
            for time at the end for you and your participants to ask any
            questions.
          </li>
          <li>
            Click
            <a class="inline" href="mailto:participantresources@rmrbenefits.com"
              >here</a
            >
            to schedule a webinar for your specific company
          </li>
          <li>
            OR email
            <a
              class="inline cursor-pointer"
              @click.prevent="copyEmail($event)"
              title="Click to Copy"
              >participantresources@rmrbenefits.com</a
            >
            directly to schedule a webinar.
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    copyEmail(event) {
      navigator.clipboard.writeText(event.target.innerText);
    }
  }
};
</script>
